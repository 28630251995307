<template>
    <section class="contact"
             v-bind:id="'el-f' + formObject.id"
             v-bind:style="formObject[$mq + '_background_full_width'] === '1' ? backgroundStyle : null"
    >
        <video class="contact__video" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" v-if="formObject[$mq + '_background_type'] === 'video' && formObject[$mq + '_background_full_width'] === '1'">
            <source v-bind:src="filesBaseUrl + formObject[$mq + '_background_url']" type="video/mp4">
        </video>
        <div v-bind:class="{ 'container': formObject[$mq + '_background_full_width'] === '0' }"
             v-bind:style="formObject[$mq + '_background_full_width'] === '0' ? backgroundStyle : null"
        >
            <video class="contact__video" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" v-if="formObject[$mq + '_background_type'] === 'video' && formObject[$mq + '_background_full_width'] === '0'">
                <source v-bind:src="filesBaseUrl + formObject[$mq + '_background_url']" type="video/mp4">
            </video>
            <div class="has-padding-30">

                <form class="contact__form"
                      enctype="multipart/form-data"
                      v-if="formObject.form_type === 'backup solution generator'"
                      v-on:submit="submitBackupSolutionGenerator"
                >
                    <h3 class="heading-3 has-margin-bottom-15 has-text-centered is-black">{{ __('Backup Solution Generator') }}</h3>
                    <div class="columns is-multiline is-centered">
                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('UPS phases') }}</label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.upsPhases" value="1">
                                <span>1:1</span>
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.upsPhases" value="2">
                                <span>1:3</span>
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.upsPhases" value="3">
                                <span>3:3</span>
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.upsPhases" value="0">
                                <span>{{ __('All options') }}</span>
                            </label>
                        </div>

                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Rack mountable') }}</label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.rackMountable" value="1">
                                <span>{{ __('Yes') }}</span>
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.rackMountable" value="2">
                                <span>{{ __('No') }}</span>
                            </label>
                            <label class="radio">
                                <input type="radio" v-model="backupSolutionGenerator.rackMountable" value="0">
                                <span>{{ __('All options') }}</span>
                            </label>
                        </div>

                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Needed load (Watts)') }}</label>
                            <div class="">
                                <input class="input is-large" type="number"
                                       v-bind:placeholder="__('Watts')"
                                       v-model="backupSolutionGenerator.neededLoad"
                                />
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Needed backup time (minutes)') }}</label>
                            <div class="">
                                <input class="input is-large" type="number"
                                       v-bind:placeholder="__('Minutes')"
                                       v-model="backupSolutionGenerator.neededBackupTime"
                                />
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop has-text-centered">
                            <div class="contact__message"
                                 v-bind:class="{ 'is-error' : formMessage.type == 'error', 'is-success' : formMessage.type == 'success'}"
                                 v-if="formMessage"
                            >
                                {{ formMessage.message }}
                            </div>
                        </div>
                        <div class="column has-justify-content-center is-12 is-flex">
                            <button class="btn is-blue is-large is-narrow" type="submit"
                                    v-bind:class="{ 'is-disabled': isDisabled, 'is-blue': !isDisabled, 'is-loading': isLoading }"
                            >
                                <span v-show="!isLoading">{{ __('Find solutions') }}</span>
                            </button>
                        </div>
                    </div>
                </form>

                <form class="contact__form form__manufacturer-warranty"
                      enctype="multipart/form-data"
                      v-if="formObject.form_type === 'warranty check'"
                      v-on:submit="submitWarrantyCheck"
                >
                    <h3 class="heading-3 has-margin-bottom-15 has-text-centered is-black">{{ __('Manufacturer Warranty') }}</h3>
                    <div class="columns is-multiline is-centered">
                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Serial number') }}</label>
                            <div class="">
                                <input class="input is-large"
                                       placeholder="ex.: 12345678912A"
                                       type="text"
                                       v-model="warrantyCheckSn"
                                />
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop" v-if="warrantyCheckResult !== ''">
                            <label class="label">{{ __('Warranty status') }}</label>
                            <span v-html=" __('Your product warranty is about to expire on {date}', { date: '<strong>' + warrantyCheckResult + '</strong>' })"
                                  v-if="warrantyCheckResult > currentDate"
                            ></span>
                            <span v-html=" __('Your product warranty expired on {date}', { date: '<strong>' + warrantyCheckResult + '</strong>' })"
                                  v-else
                            ></span>
                        </div>

                        <div class="column is-12 is-8-desktop has-text-centered">
                            <div class="contact__message"
                                 v-bind:class="{ 'is-error' : formMessage.type == 'error', 'is-success' : formMessage.type == 'success'}"
                                 v-if="formMessage"
                            >
                                {{ formMessage.message }}
                            </div>
                        </div>
                        <div class="column has-justify-content-center is-12 is-flex">
                            <button class="btn is-blue is-large is-narrow" type="submit"
                                    v-bind:class="{ 'is-disabled': isDisabled, 'is-blue': !isDisabled, 'is-loading': isLoading }"
                            >
                                <span v-show="!isLoading">{{ __('Check') }}</span>
                            </button>
                        </div>
                    </div>
                </form>

                <form class="contact__form form__replacement-battery-selector"
                      enctype="multipart/form-data"
                      v-if="formObject.form_type === 'replacement battery selector'"
                      v-on:submit="submitReplacementBatterySelector"
                >
                    <h3 class="heading-3 has-margin-bottom-15 has-text-centered is-black">{{ __('Replacement Battery Selector') }}</h3>
                    <div class="columns is-multiline is-centered">
                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Serial number') }}</label>
                            <div class="">
                                <input class="input is-large"
                                       placeholder="ex.: 12345678912A"
                                       type="text"
                                       v-model="replacementBatterySelectorSn"
                                />
                            </div>
                        </div>

                        <div class="column has-margin-top-5 is-12 is-8-desktop">
                            <div class="content-separator">
                                <div class="content-separator__text">
                                    <span>or</span>
                                </div>
                            </div>

                        </div>

                        <div class="column is-12 is-8-desktop">
                            <label class="label">{{ __('Part number') }}</label>
                            <div class="">
                                <input class="input is-large"
                                       placeholder="ex.: UPLI-LI100KU-CG01B"
                                       type="text"
                                       v-model="replacementBatterySelectorPn"
                                />
                            </div>
                        </div>

                        <div class="column has-margin-top-5 is-12 is-8-desktop">
                            <div class="content-separator">
                                <div class="content-separator__text">
                                    <span>{{ __('or') }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop" v-if="upsSeries.length">
                            <label class="label">{{ __('UPS series') }}</label>
                            <div class="">
                                <select class="select" v-model="replacementBatterySelectorSeries">
                                    <option></option>
                                    <template v-for="category in upsSeries">
                                        <optgroup v-bind:label="category.name"
                                                  v-if="category.children.length"
                                        >
                                            <option v-bind:value="series.id"
                                                    v-for="series in category.children"
                                            >{{ series.name }}</option>
                                        </optgroup>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop" v-if="upsSeries.length">
                            <label class="label">{{ __('Product name') }}</label>
                            <div class="">
                                <select class="select" v-model="replacementBatterySelectorProduct">
                                    <option></option>
                                    <option v-bind:value="product.id"
                                            v-for="product in products"
                                            v-if="products.length"
                                    >
                                        {{ product.name }}

                                        <template v-for="variant in product.variant_attributes" v-if="product.variant_attributes.length">
                                            <template v-if="variant.values.length > 1">
                                                <template v-for="value in variant.values" v-if="variant.values.length > 0">
                                                    <template v-if="variant.value == value.id">
                                                        ({{ value.value }})
                                                    </template>
                                                </template>
                                            </template>
                                        </template>

                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="column is-12 is-8-desktop has-text-centered">
                            <div class="contact__message"
                                 v-bind:class="{ 'is-error' : formMessage.type == 'error', 'is-success' : formMessage.type == 'success'}"
                                 v-if="formMessage"
                            >
                                {{ formMessage.message }}
                            </div>
                        </div>
                        <div class="column has-justify-content-center is-12 is-flex">
                            <button class="btn is-blue is-large is-narrow" type="submit"
                                    v-bind:class="{ 'is-disabled': isDisabled, 'is-blue': !isDisabled, 'is-loading': isLoading }"
                            >
                                <span v-show="!isLoading">{{ __('Find solutions') }}</span>
                            </button>
                        </div>

                        <div class="column is-12 is-8-desktop" ref="replacementBatterySelectorResultContainer"  v-if="replacementBatterySelectorResult.length">
                            <label class="label">{{ __('Results') }}</label>

                            <div class="columns product-list is-multiline">
                                <div class="column is-12-mobile is-4-tablet product-list__product"
                                     v-for="(compatibleProduct, index) in replacementBatterySelectorResult"
                                >
                                    <div class="card">
                                        <div class="card__heading">
                                            <div class="thumbnail-wrapper">
                                                <router-link class="thumbnail"
                                                             target="_blank"
                                                             v-bind:to="{ name : 'products.show', params : { product : compatibleProduct.slug, pn: compatibleProduct.pn } }"
                                                >
                                                    <img v-bind:alt="compatibleProduct.name" v-bind:src="filesBaseUrl + (compatibleProduct.files.Pictures[0] ? (compatibleProduct.files.Pictures[0].thumbnail_path != '' ? compatibleProduct.files.Pictures[0].thumbnail_path : compatibleProduct.files.Pictures[0].path) : '')" v-if="compatibleProduct.files && compatibleProduct.files.Pictures" />
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="card__body">
                                            <router-link class="card__body__title"
                                                         target="_blank"
                                                         v-bind:to="{ name : 'products.show', params : { product : compatibleProduct.slug, pn: compatibleProduct.pn } }"
                                            >
                                                {{ compatibleProduct.name }}
                                            </router-link>
                                            <div class="card__body__features is-fullheight"
                                                 v-bind:id="'short-description-' + index"
                                                 v-if="isVisibleFullDescription[index]"
                                                 v-on:mouseleave="hideFullDescription(index)"
                                            >
                                                <div v-for="shortDescription in compatibleProduct.short_description"
                                                     v-html="shortDescription"
                                                >
                                                </div>
                                            </div>
                                            <div class="card__body__features">
                                                <div v-on:mouseover="showFullDescription(index)">
                                                    <div class="product-list__product__short-description"
                                                         v-for="shortDescription in compatibleProduct.short_description"
                                                         v-html="shortDescription"
                                                    >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card__body__variants__container is-fullheight"
                                                 v-bind:id="'variant-attributes-' + index"
                                                 v-if="isVisibleAllVariantAttributes[index]"
                                                 v-on:mouseleave="hideAllVariantAttributes(index)"
                                            >
                                                <template v-for="variant in compatibleProduct.variant_attributes" v-if="compatibleProduct.variant_attributes">
                                                    <div class="card__body__variants__container__title">{{ variant.name }}</div>
                                                    <div class="card__body__variants">
                                                        <template v-for="(value, key) in variant.values" v-if="variant.values.length > 1">
                                                            <a class="card__body__variants__option"
                                                               v-bind:class="{ 'is-active': (index in selectedVariants) && (variant.id in selectedVariants[index]) && (selectedVariants[index][variant.id] === value.id) }"
                                                               v-on:click="selectVariant(compatibleProduct, index, variant.id, value.id, true)"
                                                            >
                                                                {{ value.value }}
                                                            </a>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="card__body__variants__container">
                                                <template v-if="compatibleProduct.variant_attributes" v-for="variant in compatibleProduct.variant_attributes">
                                                    <div class="card__body__variants__container__title" v-if="variant.values.length > 1">{{ variant.name }}</div>
                                                    <div class="card__body__variants"
                                                         v-on:mouseover="showAllVariantAttributes(index)"
                                                    >
                                                        <template v-for="(value, key) in variant.values" v-if="variant.values.length > 1">
                                                            <a class="card__body__variants__option"
                                                               v-bind:class="{ 'is-active': (index in selectedVariants) && (variant.id in selectedVariants[index]) && (selectedVariants[index][variant.id] === value.id) }"
                                                               v-on:click="selectVariant(compatibleProduct, index, variant.id, value.id, true)"
                                                            >
                                                                {{ value.value }}
                                                            </a>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>

                                            <template v-if="shop">
                                                <div class="card__footer">
                                                    <div>
                                                            <span class="card__footer__price"
                                                                  v-if="isAvailable(compatibleProduct) && (isInStock(compatibleProduct) || (!isInStock(compatibleProduct) && !hasPromotion(compatibleProduct)))"
                                                            >
                                                                {{ currencyPosition === 'left' ? currencySign : ''}}{{ compatibleProduct.price.price }} {{ currencyPosition === 'right' ? currencySign : '' }}
                                                            </span>
                                                        <span class="card__footer__not-available"
                                                              v-if="isNotAvailable(compatibleProduct)"
                                                        >
                                                            {{ __('Not available in your region') }}
                                                        </span>
                                                    </div>
                                                    <div v-if="hasPromotion(compatibleProduct)">
                                                            <span class="card__footer__price">
                                                                {{ currencyPosition === 'left' ? currencySign : ''}}<span>{{ compatibleProduct.promotion.price }}</span> {{ currencyPosition === 'right' ? currencySign : '' }}
                                                            </span>
                                                    </div>
                                                    <span class="card__footer__status"
                                                          v-bind:class="isInStock(compatibleProduct) ? 'has-icon-checked' : 'has-icon-delete'"
                                                          v-html="isInStock(compatibleProduct) ? __('In stock') :  __('Not in stock')"
                                                          v-if="isAvailable(compatibleProduct)"
                                                    >
                                                    </span>
                                                </div>
                                                <a class="btn is-block is-yellow"
                                                   v-bind:class="{ 'is-disabled': !compatibleProduct.price.stoc || compatibleProduct.price.is_available === '0' || compatibleProduct.price.price === 0 }"
                                                   v-if="isAvailable(compatibleProduct) && (isInStock(compatibleProduct) || (!isInStock(compatibleProduct) && !hasPromotion(compatibleProduct)))"
                                                   v-on:click="addToCart(compatibleProduct, 1, index)"
                                                >
                                                    {{ __('Add to cart') }}
                                                </a>
                                                <a class="btn is-block is-blue"
                                                   v-if="hasPromotion(compatibleProduct)"
                                                   v-on:click="preorder(compatibleProduct, index)"
                                                >
                                                    {{ __('Pre-order') }}
                                                </a>
                                                <a class="btn is-block is-yellow is-disabled"
                                                   v-if="isNotAvailable(compatibleProduct)"
                                                >
                                                    {{ __('Not available') }}
                                                </a>
                                                <a class="btn is-block is-yellow is-disabled"
                                                   v-if="compatibleProduct.wp_status === 'discontinued'"
                                                >
                                                    {{ __('Discontinued') }}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <form class="contact__form newsletter"
                      enctype="multipart/form-data"
                      v-if="formObject.form_type === 'newsletter'"
                      v-on:submit="submitNewsletter"
                >
                    <div class="has-margin-bottom-30"
                         ref="newsletterBody"
                         v-bind:style="newsletterStyle"
                         v-html="formObject[$mq + '_text']"
                    ></div>
                    <div v-bind:style="newsletterStyle">
                        <div class="has-margin-bottom-15" v-if="!newsletterSubscriptionSuccess">
                                <input class="input"
                                       type="email"
                                        v-bind:placeholder="__('Email address')"
                                       v-model="newsletterEmail"
                                />
                                <small class="help is-danger" v-if="errors && errors.email">{{ errors.email }}</small>
                        </div>
                        <div class="has-margin-bottom-30" v-if="!newsletterSubscriptionSuccess">
                                <input class="input"
                                       type="text"
                                       v-bind:placeholder="__('Your name')"
                                       v-model="newsletterName"
                                />
                                <small class="help is-danger" v-if="errors && errors.name">{{ errors.name }}</small>
                        </div>


                        <div class="contact__message has-margin-bottom-15"
                             v-bind:class="{ 'is-error' : formMessage.type == 'error', 'is-success' : formMessage.type == 'success'}"
                             v-if="formMessage"
                        >
                            {{ formMessage.message }}
                        </div>

                        <div class="contact__message has-margin-bottom-15 is-success" v-if="newsletterSubscriptionSuccess">
                            {{ __('CONFIRM SUBSCRIPTION. A link has been sent to your email, which you must click to confirm your subscription. Thank you!') }}
                        </div>
                        <div class="newsletter-actions" v-else>
                            <button class="btn has-margin-right-15 is-blue is-large is-narrow" type="submit"
                                    v-bind:class="{ 'is-disabled': isDisabled, 'is-blue': !isDisabled, 'is-loading': isLoading }"
                            >
                                <span v-show="!isLoading">{{ __('Join') }}</span>
                            </button>
                            <a class="btn has-border-dashed-black is-hidden"
                               v-on:click="closeNewsletterModal"
                            >
                                {{ __('No thanks') }}
                            </a>
                        </div>
                    </div>
                </form>

                <div v-if="formObject.form_type === 'gdpr'">
                    <div class="form-gdpr is-flex">
                        <div v-html="formObject[$mq + '_text']"></div>
                        <div class="form-gdpr__checkbox-container">
                            <toggle-button
                                           color="#252b8f"
                                           v-bind:font-size="16"
                                           v-bind:labels="{ checked: __('Yes'), unchecked: __('No') }"
                                           v-bind:width="60"
                                           v-bind:value="false"
                                           v-if="gdprCheckboxes[0] !== formObject.id"
                                           v-model="gdprCheckboxValue"
                            />
                            <span class="has-smaller-line-height has-text-blue has-text-centered" v-else>
                                {{ __('Always active') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="showCartModal">
            <div class="product__added-to-cart">
                <div class="columns is-gapless">
                    <div class="column is-3 ">
                        <img v-bind:alt="modalProduct.name" v-bind:src="filesBaseUrl + (modalProduct.files.Pictures && modalProduct.files.Pictures[0] ? modalProduct.files.Pictures[0].path : '')" v-if="Object.keys(modalProduct.files).length > 0" />
                    </div>
                    <div class="column">
                        <h5 class="title is-5">{{ modalProduct.name }}</h5>
                        <p>{{ __('This product has been added to your cart') }}</p><br />
                        <div class="is-flex">
                            <a class="btn is-blue is-fullwidth" v-on:click="viewCart">{{ __('View your cart') }}</a>
                            <button class="btn has-margin-left-10 is-blue-outlined is-thin is-fullwidth" v-on:click="closeModal">{{ __('Continue shopping') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal v-if="showStockErrorModal">
            <div class="product__added-to-cart">
                <div class="columns is-gapless">
                    <div class="column is-3 ">
                        <img v-bind:alt="modalProduct.name" v-bind:src="filesBaseUrl + (modalProduct.files.Pictures && modalProduct.files.Pictures[0] ? modalProduct.files.Pictures[0].path : '')" v-if="Object.keys(modalProduct.files).length > 0" />
                    </div>
                    <div class="column">
                        <h5 class="title is-5   ">{{ modalProduct.name }}</h5>
                        <p>{{ __('This product is out of stock') }}</p><br />
                        <div class="is-flex">
                            <router-link class="btn is-blue is-fullwidth" v-bind:to="{ name : 'cart' }">{{ __('View your cart') }}</router-link>
                            <button class="btn has-margin-left-10 is-blue-outlined is-thin is-fullwidth" v-on:click="showStockErrorModal = false">{{ __('Continue shopping') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal v-if="showPreorderModal">
            <div class="product__added-to-cart">
                <div class="columns is-gapless">
                    <div class="column is-3 ">
                        <img v-bind:alt="modalProduct.name" v-bind:src="filesBaseUrl + (modalProduct.files.Pictures && modalProduct.files.Pictures[0] ? modalProduct.files.Pictures[0].path : '')" v-if="Object.keys(modalProduct.files).length > 0" />
                    </div>
                    <div class="column">
                        <h5 class="title is-5   ">{{ modalProduct.name }}</h5>
                        <p>
                            {{ __('The product is only available for pre-order.') }}<br/>
                            {{ __('Delivery date') }}: {{ modalProduct.promotion.delivery_date }}
                        </p>
                        <br />
                        <div class="is-flex">
                            <button class="btn is-blue is-fullwidth" v-on:click="preorderAddToCart(modalProduct, 1, newCartProductIndex)">{{ __('Pre-order') }}</button>
                            <button class="btn has-margin-left-10 is-blue-outlined is-thin is-fullwidth" v-on:click="closePreorderModal">{{ __('Reconsider') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
    import Modal from './Modal.vue';
    import { eventBus } from '../eventBus';
    import ToggleButton from './ToggleButton.vue';
    import mixins from "../mixins.js";

    export default {
        beforeDestroy: function() {
            eventBus.$off(['images-loaded', 'changed-el-in-query'], this.scrollToEl);
        },
        components: {
            Modal,
            ToggleButton,
        },
        mixins: [mixins],
        computed: {
            backgroundStyle: function () {
                let type = this.$mq;
                if (this.formObject[type + '_background_type'] === 'color') {
                    return 'background-color: ' + this.formObject[type + '_background_color']  + ';';
                }

                if (this.formObject[type + '_background_type']  === 'picture') {
                    let style = 'background-image: url("' + this.filesBaseUrl + this.formObject[type + '_background_url']  + '");';
                    style = style + 'background-repeat: no-repeat;';

                    if (this.formObject[type + '_background_fixed_point']) {
                        let positions = this.formObject[type + '_background_fixed_point'].split('-');
                        if (positions.length === 2) {
                            style = style + 'background-position: ' + positions[0] + '% ' + positions[1] + '%';
                        }

                    }

                    return style;
                }

                return null;
            },
            newsletterStyle: function () {
                if (!this.newsletterBodyMarginLeft) {
                    return;
                }

                return 'margin-left: ' + this.newsletterBodyMarginLeft + 'px';
            },
        },
        created: function () {
            if (this.formObject.form_type === 'backup solution generator' && !this.user) {
                this.formObject.form_type = '';
                parent.location.href = this.baseUrl + 'authentication-required?redirect=' + encodeURIComponent(parent.location.hash.replace('#/',''));
                return null;
            }

            this.getCurrentDate();

            if (this.formObject.mobile_background_color === null) {
                this.formObject.mobile_background_color = this.formObject.desktop_background_color;
            }
            if (this.formObject.mobile_background_fixed_point === null) {
                this.formObject.mobile_background_fixed_point = this.formObject.desktop_background_fixed_point;
            }
            if (this.formObject.mobile_background_full_width === null) {
                this.formObject.mobile_background_full_width = this.formObject.desktop_background_full_width;
            }
            if (this.formObject.mobile_background_type === null) {
                this.formObject.mobile_background_type = this.formObject.desktop_background_type;
                this.formObject.mobile_background_color = this.formObject.desktop_background_color;
            }
            if (this.formObject.mobile_background_url === null) {
                this.formObject.mobile_background_url = this.formObject.desktop_background_url;
            }
            if (this.formObject.mobile_text === null) {
                this.formObject.mobile_text = this.formObject.desktop_text;
            }

            if (this.formObject.form_type === 'replacement battery selector') {
                this.getUpsCategories();
            }

            eventBus.$on(['images-loaded', 'changed-el-in-query'], this.scrollToEl);

        },
        data: function () {
            return {
                backupSolutionGenerator: {
                    upsPhases: 0,
                    rackMountable: 0,
                    neededLoad: '',
                    neededBackupTime: ''
                },
                currentDate: '',
                errors: {},
                formMessage: null,
                gdprCheckboxValue: false,
                isDisabled: true,
                isLoading: false,
                isVisibleFullDescription: [],
                isVisibleAllVariantAttributes: [],
                message: '',
                newCartProductIndex: 0,
                newsletterBodyMarginLeft: null,
                newsletterEmail: '',
                newsletterName: '',
                newsletterSubscriptionSuccess: false,
                products: [],
                replacementBatterySelectorPn: '',
                replacementBatterySelectorProduct: '',
                replacementBatterySelectorResult: [],
                replacementBatterySelectorSeries: '',
                replacementBatterySelectorSn: '',
                selectedVariants: {},
                showCartModal: false,
                showPreorderModal: false,
                showStockErrorModal: false,
                upsSeries: [],
                warrantyCheckResult: '',
                warrantyCheckSn: '',
            }
        },
        methods: {
            addToCart: function (newProduct, quantity, index) {
                if (!newProduct.price.stoc || newProduct.price.is_available === '0' || newProduct.price.price === 0) {
                    return;
                }

                this.$Progress.start();
                this.modalProduct = newProduct;

                if (!newProduct.price.stoc || newProduct.price.is_available === '0' || newProduct.price.price === 0) {
                    return;
                }
                this.$Progress.start();
                this.newCartProduct = newProduct;
                this.$http.get(this.apiBaseUrl + 'product/' + newProduct.id + '?pn=' + newProduct.pn + '&lang=' + this.language + '&country=' + this.country + '&currency=' + this.currency).then(function (response) {
                    newProduct = response.body;

                    this.hasInStock(newProduct.stockId)
                        .then((response) => {
                            if (response === false) {
                                this.showStockErrorModal = true;
                                return;
                            }

                            if (Object.keys(this.selectedVariants).length > 0 && this.selectedVariants[index] && Object.keys(this.selectedVariants[index]).length > 0) {
                                newProduct.selectedVariants = {};
                                Object.assign(newProduct.selectedVariants, this.selectedVariants[index]);
                            }
                            let alreadyInCart = false;
                            let shopCart = this.cart ? this.cart : [];
                            shopCart.forEach(function (product) {
                                if (product.id === newProduct.id && (!product.hasOwnProperty('selectedVariants') || !newProduct.hasOwnProperty('selectedVariants') || (product.hasOwnProperty('selectedVariants') && newProduct.hasOwnProperty('selectedVariants') && JSON.stringify(product.selectedVariants) === JSON.stringify(newProduct.selectedVariants))) ) {
                                    product.quantity = (product.quantity + quantity) <= newProduct.price.stoc  ? (product.quantity + quantity) : newProduct.price.stoc;
                                    alreadyInCart = true;

                                }
                            });

                            if (!alreadyInCart) {
                                newProduct.quantity = quantity;
                                let newProductCopy = {};
                                Object.assign(newProductCopy, newProduct);

                                newProductCopy.shortDescription = [];
                                if (newProductCopy.folders && newProductCopy.folders['Short description']) {
                                    newProductCopy['shortDescription'] = newProductCopy.folders['Short description'];
                                }

                                shopCart.push(newProductCopy);
                            }

                            this.updateCart(shopCart);
                            if (this.$route.query.modal) {
                                parent.window.vueApp.updateCart(shopCart);
                            }

                            this.showCartModal = true;
                        })
                        .catch(error => console.error(error))
                        .finally(() => this.$Progress.finish());
                }.bind(this), function (response) {
                    console.log('There was an error fetching data from API. Error code ' + response.status);

                    this.$Progress.fail();
                }.bind(this));

            },
            closeModal: function () {
                this.showCartModal = false;
            },
            closeNewsletterModal: function () {
                window.vueApp.closeNewsletterModal();
            },
            closePreorderModal: function () {
                this.showPreorderModal = false;
            },
            getCurrentDate: function () {
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();

                this.currentDate = yyyy + '-' + mm + '-' + dd;
            },
            getNewsletterBodyMarginLeft: function () {
                if (this.formObject.form_type !== 'newsletter') {
                    return;
                }

                if (this.$mq === 'mobile') {
                    return;
                }

                this.newsletterBodyMarginLeft = 275;
            },
            getProducts: function () {
                this.products = [];

                // build filter URL params
                let filterParams = 'lang=' + this.language + '&country=' + this.country + '&currency=' + this.currency + '&filter[category_id]=' + this.replacementBatterySelectorSeries + '&show_in_search=true';

                this.$http.get(this.apiBaseUrl + 'products?' + filterParams).then(function (response) {
                    this.products = response.body;

                }.bind(this), function (response) {
                    console.log('There was an error fetching data from API. Error code ' + response.status);

                    this.$Progress.fail();
                }.bind(this));
            },
            getUpsCategories: function () {
                this.$http.get(this.apiBaseUrl + 'categories?filter[name]=ups').then(function (response) {
                    if (!response.body.length) {
                        return;
                    }
                    this.upsSeries = response.body[0].children;

                    this.$nextTick(() => {
                        eventBus.$emit('content-height-changed');
                    });
                }.bind(this));
            },
            hasPromotion: function (product) {
                return product.wp_status === 'active'
                    && (product.price.is_available === '0' || parseFloat(product.price.price) === 0 || parseFloat(product.price.stoc) === 0)
                    && (typeof(product.promotion) !== 'undefined' && product.promotion !== null && parseFloat(product.promotion.price) > 0);
            },
            hideAllVariantAttributes: function (index) {
                this.isVisibleAllVariantAttributes[index] = false;
            },
            hideFullDescription: function (index) {
                this.isVisibleFullDescription[index] = false;
            },
            isAvailable: function (product) {
                return product.wp_status === 'active'
                    && product.price.is_available === '1'
                    && parseFloat(product.price.price) > 0
            },
            isInStock: function (product) {
                return product.price.stoc > 0
                    && product.wp_status === 'active'
                    && product.price.is_available === '1'
                    && parseFloat(product.price.price) > 0;

            },
            isNotAvailable: function (product) {
                return product.wp_status === 'active'
                    && (product.price.is_available === '0' || parseFloat(product.price.price) === 0)
                    && (typeof(product.promotion) === 'undefined' || product.promotion === null || parseFloat(product.promotion.price) === 0);
            },
            preorder: function (newProduct) {
                this.modalProduct = newProduct;
                this.newCartProductIndex = index;
                this.showPreorderModal = true;
            },
            preorderAddToCart: function (newProduct, quantity, index) {
                this.$Progress.start();
                this.showPreorderModal = false;

                this.newCartProduct = newProduct;
                this.newCartProduct.price.price = newProduct.promotion.price;
                this.$http.get(this.apiBaseUrl + 'product/' + newProduct.id + '?pn=' + newProduct.pn + '&lang=' + this.language + '&country=' + this.country + '&currency=' + this.currency).then(function (response) {
                    newProduct = response.body;

                    if (Object.keys(this.selectedVariants).length > 0 && this.selectedVariants[index] && Object.keys(this.selectedVariants[index]).length > 0) {
                        newProduct.selectedVariants = {};
                        Object.assign(newProduct.selectedVariants, this.selectedVariants[index]);
                    }
                    let alreadyInCart = false;
                    let shopCart = this.cart ? this.cart : [];
                    shopCart.forEach(function (product) {
                        if (product.id === newProduct.id && (!product.hasOwnProperty('selectedVariants') || !newProduct.hasOwnProperty('selectedVariants') || (product.hasOwnProperty('selectedVariants') && newProduct.hasOwnProperty('selectedVariants') && JSON.stringify(product.selectedVariants) === JSON.stringify(newProduct.selectedVariants))) ) {
                            product.quantity = product.quantity + quantity;
                            alreadyInCart = true;

                        }
                    });

                    if (!alreadyInCart) {
                        newProduct.quantity = quantity;
                        newProduct.price.price = newProduct.promotion.price;
                        let newProductCopy = {};
                        Object.assign(newProductCopy, newProduct);

                        newProductCopy.shortDescription = [];
                        if (newProductCopy.folders && newProductCopy.folders['Short description']) {
                            newProductCopy['shortDescription'] = newProductCopy.folders['Short description'];
                        }

                        shopCart.push(newProductCopy);
                    }

                    this.updateCart(shopCart);

                    this.showCartModal = true;

                    this.$Progress.finish();
                }.bind(this), function (response) {
                    console.log('There was an error fetching data from API. Error code ' + response.status);

                    this.$Progress.fail();
                }.bind(this));
            },
            scrollToEl: function () {
                if (this.$route.query.el && this.$route.query.el === 'f' + this.formObject.id) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.$scrollTo(document.getElementById('el-' + this.$route.query.el), {
                                offset: -150
                            });
                        }, 300);
                    });
                }
            },
            showAllVariantAttributes: function (index) {
                this.isVisibleAllVariantAttributes[index] = true;

                this.$nextTick(function () {
                    let allVariantAttributesElement = document.getElementById('variant-attributes-' + index);
                    allVariantAttributesElement.classList.add('has-nowrap');
                    if (allVariantAttributesElement.offsetWidth <= 235) {
                        this.isVisibleAllVariantAttributes[index] = false;
                    }
                    allVariantAttributesElement.classList.remove('has-nowrap');
                }.bind(this));

            },
            showFile: function (blob, filename) {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                var newBlob = new Blob([blob], {type: "application/pdf"})

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                link.download = filename + ".pdf";
                link.click();
                setTimeout(function(){
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
            },
            showFullDescription: function (index) {
                this.isVisibleFullDescription[index] = true;
                this.$nextTick(function () {
                    let shortDescriptionElement = document.getElementById('short-description-' + index);
                    shortDescriptionElement.classList.add('product-list__product__short-description');
                    if (shortDescriptionElement.offsetHeight < 95 && shortDescriptionElement.offsetWidth <= 220) {
                        this.isVisibleFullDescription[index] = false;
                    }
                    shortDescriptionElement.classList.remove('product-list__product__short-description');
                }.bind(this));

            },
            submitBackupSolutionGenerator: function (e) {
                e.preventDefault();

                if (this.isDisabled) {
                    return null;
                }

                this.formMessage = null;
                this.isLoading = true;

                this.$http.post(this.apiBaseUrl + 'backup_solution_generator', {
                    'needed_load': this.backupSolutionGenerator.neededLoad,
                    'needed_backup_time': this.backupSolutionGenerator.neededBackupTime,
                    'ups_phases': this.backupSolutionGenerator.upsPhases,
                    'rack_mountable': this.backupSolutionGenerator.rackMountable,
                    'country': this.country,
                    'currency': this.currency
                }, {
                    emulateJSON: true,
                    responseType: 'blob',
                }).then(function (response) {
                    this.showFile(response.body, 'nJoy-Backup-Solution-Generator');

                    this.formMessage = {
                        message: this.__('Downloaded') + '!',
                        type: 'success'
                    }
                    this.isLoading = false;
                }.bind(this), function (response) {
                    console.log('There was an error sending data to API. Error code ' + response.status);

                    this.formMessage = {
                        message: response.body.error,
                        type: 'error'
                    }
                    this.isLoading = false;
                }.bind(this));
            },
            submitNewsletter: function (e) {
                e.preventDefault();

                if (this.isDisabled) {
                    return null;
                }

                this.errors = null;
                this.formMessage = null;
                this.isLoading = true;

                this.$http.post(this.apiBaseUrl + 'newsletter_subscribe?', {
                    'email': this.newsletterEmail,
                    'name': this.newsletterName,
                    'lang': this.language,
                }, {
                    emulateJSON: true,
                }).then(function (response) {
                    this.formMessage = response.body;
                    this.newsletterEmail = '';
                    this.newsletterName = '';
                    this.isLoading = false;
                    this.newsletterSubscriptionSuccess = true;

                    if (import.meta.env.VITE_ENVIRONMENT === 'production') {
                        gtag('event', 'conversion', {'send_to': 'AW-343690656/xnCjCLvvoYIDEKCb8aMB'});
                    }
                }.bind(this), function (response) {
                    if (response.status === 422 && response.body.errors) {
                        this.errors = response.body.errors;
                        this.isLoading = false;
                        return;
                    }

                    console.log('There was an error sending data to API. Error code ' + response.status);

                    this.formMessage = {
                        message: response.body.error,
                        type: 'error'
                    }
                    this.isLoading = false;
                }.bind(this));
            },
            submitReplacementBatterySelector: function (e) {
                e.preventDefault();

                this.isVisibleFullDescription = [];
                this.isVisibleAllVariantAttributes = [];

                if (this.isDisabled) {
                    return null;
                }

                this.formMessage = null;
                this.replacementBatterySelectorResult = [];
                this.isLoading = true;

                let filter = 'lang=' + this.language + '&country=' + this.country + '&currency=' + this.currency;

                if (this.replacementBatterySelectorProduct !== '') {
                    filter = filter + '&pid=' + this.replacementBatterySelectorProduct
                }

                if (this.replacementBatterySelectorPn !== '') {
                    filter = filter + '&pn=' + this.replacementBatterySelectorPn
                }

                if (this.replacementBatterySelectorSn !== '') {
                    filter = filter + '&sn=' + this.replacementBatterySelectorSn
                }

                this.$http.get(this.apiBaseUrl + 'replacement_battery_selector?' + filter).then(function (response) {
                    if (!response.body.length) {
                        this.formMessage = {
                            message: this.__('No compatible batteries found for the selected product. Please make sure your product is an external battery cabinet or a UPS.'),
                            type: 'error'
                        }

                        this.isLoading = false;
                        return;

                    }

                    this.replacementBatterySelectorResult = response.body;
                    this.isLoading = false;

                    this.$nextTick(() => {
                        this.$scrollTo(this.$refs.replacementBatterySelectorResultContainer, {offset: -100})
                    });
                }.bind(this), function (response) {
                    console.log('There was an error sending data to API. Error code ' + response.status);

                    this.formMessage = {
                        message: response.body.error,
                        type: 'error'
                    }
                    this.isLoading = false;
                }.bind(this));
            },
            submitWarrantyCheck: function (e) {
                e.preventDefault();

                if (this.isDisabled) {
                    return null;
                }

                this.formMessage = null;
                this.warrantyCheckResult = '';
                this.isLoading = true;

                this.$http.get(this.apiBaseUrl + 'warranty_check?sn=' + this.warrantyCheckSn).then(function (response) {
                    if (response.body.data.data === '') {
                        this.formMessage = {
                            message: this.__('You have entered an invalid serial number. Please try again.'),
                            type: 'error'
                        }
                        this.isLoading = false;
                        return;
                    }

                    this.warrantyCheckResult = response.body.data.waranty_end;
                    this.isLoading = false;
                }.bind(this), function (response) {
                    console.log('There was an error sending data to API. Error code ' + response.status);

                    this.formMessage = {
                        message: response.body.error,
                        type: 'error'
                    }
                    this.isLoading = false;
                }.bind(this));
            },
            validateBackupSolutionGenerator: function () {
                if (this.backupSolutionGenerator.neededLoad === ''
                    || this.backupSolutionGenerator.neededBackupTime === ''
                    || this.backupSolutionGenerator.upsPhases === ''
                    || this.backupSolutionGenerator.rackMountable === ''
                ) {
                    this.isDisabled = true;
                    return null;
                }

                this.isDisabled = false;
            },
            validateNewsletterSubscription: function () {
                if (this.newsletterEmail === ''
                    || this.newsletterName === ''
                ) {
                    this.isDisabled = true;
                    return null;
                }

                this.isDisabled = false;
            },
            validateReplacementBatterySelector: function () {
                if (this.replacementBatterySelectorPn === ''
                    && this.replacementBatterySelectorProduct === ''
                    && this.replacementBatterySelectorSn === ''
                ) {
                    this.isDisabled = true;
                    return null;
                }

                this.isDisabled = false;
            },
            validateWarrantyCheck: function() {
                if (this.warrantyCheckSn.neededLoad === '') {
                    this.isDisabled = true;
                    return null;
                }

                this.isDisabled = false;
            },
            viewCart: function () {
                if (this.$route.query.modal) {
                    parent.vueApp.$router.push({ name: 'cart' });
                } else {
                    this.$router.push({ name: 'cart' });
                }
            },
        },
        mounted: function () {
            this.$nextTick(() => {
                if (this.$el.querySelector('img') === null &&
                    this.$parent.$el.nodeName !== '#comment' &&
                    this.$parent.$el.querySelector('img') === null
                ) {
                    this.scrollToEl();
                }

                this.getNewsletterBodyMarginLeft();
            });
        },
        props: {
            formObject: Object,
            gdprCheckboxes: {
                default: function () {
                    return [];
                },
                type: Array
            }
        },
        watch: {
            'backupSolutionGenerator.neededBackupTime': function () {
                this.validateBackupSolutionGenerator();
            },
            'backupSolutionGenerator.neededLoad': function () {
                this.validateBackupSolutionGenerator();
            },
            'backupSolutionGenerator.rackMountable': function () {
                this.validateBackupSolutionGenerator();
            },
            'backupSolutionGenerator.upsPhases': function () {
                this.validateBackupSolutionGenerator();
            },
            newsletterEmail: function () {
                this.validateNewsletterSubscription();
            },
            newsletterName: function () {
                this.validateNewsletterSubscription();
            },
            replacementBatterySelectorPn: function () {
                this.validateReplacementBatterySelector();
            },
            replacementBatterySelectorProduct: function () {
                this.validateReplacementBatterySelector();
            },
            replacementBatterySelectorSeries: function () {
                this.getProducts();
                this.replacementBatterySelectorProduct = '';
                this.validateReplacementBatterySelector();
            },
            replacementBatterySelectorSn: function () {
                this.validateReplacementBatterySelector();
            },
            warrantyCheckSn: function () {
                this.validateWarrantyCheck();
            }
        }
    }
</script>
