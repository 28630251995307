export default [
    {
        path: '/',
        component: () => import('./views/Home.vue'),
        name: 'home'
    },
    {
        path: '/about',
        component: () => import('./views/About.vue'),
        name: 'about'
    },
    {
        path: '/distributors',
        component: () => import('./views/Distributors.vue'),
        name: 'distributors'
    },
    {
        path: '/download/:fileName/:niceName',
        component: () => import('./views/DownloadFile.vue'),
        name: 'download.file'
    },
    {
        path: '/partnership',
        component: () => import('./views/Partnership.vue'),
        name: 'partnership'
    },

    // auth
    {
        path: '/login',
        component: () => import('./views/auth/Login.vue'),
        name: 'login'
    },
    {
        path: '/login-as',
        component: () => import('./views/auth/LoginAs.vue'),
        name: 'login-as'
    },
    {
        path: '/register',
        component: () => import('./views/auth/Register.vue'),
        name: 'register'
    },
    {
        path: '/register/verify',
        component: () => import('./views/auth/RegisterVerify.vue'),
        name: 'register.verify'
    },
    {
        path: '/reset-password',
        component: () => import('./views/auth/ResetPassword.vue'),
        name: 'reset-password'
    },
    // my account
    {
        path: '/my-account/settings',
        component: () => import('./views/my-account/MyAccountSettings.vue'),
        name: 'my-account.settings'
    },
    {
        path: '/my-orders/cancellation/:id',
        component: () => import('./views/my-account/MyOrderCancellation.vue'),
        name: 'my-account.my-order.cancellation'
    },
    {
        path: '/my-orders/:id',
        component: () => import('./views/my-account/MyOrder.vue'),
        name: 'my-account.my-order'
    },
    {
        path: '/my-orders',
        component: () => import('./views/my-account/MyOrders.vue'),
        name: 'my-account.my-orders'
    },
    // newsletter
    {
        path: '/newsletter/unsubscribe',
        component: () => import('./views/newsletter/NewsletterUnsubscribe.vue'),
        name: 'newsletter.unsubscribe'
    },
    {
        path: '/newsletter/confirm',
        component: () => import('./views/newsletter/NewsletterConfirm.vue'),
        name: 'newsletter.confirm'
    },
    {
        path: '/my-products',
        component: () => import('./views/my-account/MyProducts.vue'),
        name: 'my-account.my-products'
    },
    {
        path: '/register-a-new-product',
        component: () => import('./views/my-account/RegisterNewProduct.vue'),
        name: 'my-account.register-new-product'
    },
    {
        path: '/request-service/rating/:id',
        component: () => import('./views/my-account/RequestServiceRating.vue'),
        name: 'my-account.request-service.rating'
    },
    {
        path: '/request-service/:sn',
        component: () => import('./views/my-account/RequestService.vue'),
        name: 'my-account.request-service'
    },
    {
        path: '/request-ups-installation/:sn',
        component: () => import('./views/my-account/RequestUpsInstallation.vue'),
        name: 'my-account.request-ups-installation'
    },
    {
        path: '/request-ups-maintenance/:sn',
        component: () => import('./views/my-account/RequestUpsMaintenance.vue'),
        name: 'my-account.request-ups-maintenance'
    },
    {
        path: '/return-product/:pn',
        component: () => import('./views/my-account/ReturnProduct.vue'),
        name: 'my-account.return-product'
    },
    {
        path: '/ups-installation/:id',
        component: () => import('./views/my-account/UpsInstallationDetails.vue'),
        name: 'my-account.ups-installation'
    },
    {
        path: '/ups-maintenance/:id',
        component: () => import('./views/my-account/UpsMaintenanceDetails.vue'),
        name: 'my-account.ups-maintenance'
    },
    // products
    {
        path: '/products/:category/overview',
        component: () => import('./views/products/Series.vue'),
        name: 'products.series'
    },
    {
        path: '/products/:category',
        component: () => import('./views/products/Index.vue'),
        name: 'products.index'
    },
    {
        path: '/product/:product/:pn?',
        component: () => import('./views/products/Show.vue'),
        name: 'products.show'
    },
    {
        path: '/search',
        component: () => import('./views/products/SearchResults.vue'),
        name: 'products.search'
    },

    // shop
    {
        path: '/cart',
        component: () => import('./views/shop/Cart.vue'),
        name: 'cart'
    },
    {
        path: '/checkout',
        component: () => import('./views/shop/Checkout.vue'),
        name: 'checkout'
    },
    {
        path: '/checkout/error',
        component: () => import('./views/shop/Error.vue'),
        name: 'checkout.error'
    },
    {
        path: '/checkout/pending',
        component: () => import('./views/shop/TransactionPending.vue'),
        name: 'checkout.pending'
    },
    {
        path: '/checkout/thank-you',
        component: () => import('./views/shop/ThankYou.vue'),
        name: 'checkout.thank-you'
    },

    // solutions
    /*{
        path: '/solutions',
        component: () => import('./views/solutions/Index.vue'),
        name: 'solutions.index'
    },
    {
        path: '/solutions/:solution',
        component: () => import('./views/solutions/Show.vue'),
        name: 'solutions.show'
    },*/

    // success stories
    {
        path: '/success-stories',
        component: () => import('./views/success-stories/Index.vue'),
        name: 'success-stories.index'
    },
    {
        path: '/success-stories/:story',
        component: () => import('./views/success-stories/Show.vue'),
        name: 'success-stories.show'
    },



    // defaults
    {
        path: '/error404',
        component: () => import('./views/errors/404.vue'),
        name: 'error404'
    },
    {
        path: '/:page',
        component: () => import('./views/Page.vue'),
        name: 'page'
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('./views/errors/404.vue'),
    }

]
