import store from "./store.js";
import router from "./router.js";
import axios from "axios";
import { sprintf } from 'sprintf-js';

export default {
    computed: {
        activeXhrRequests: function () {
            return store.state.activeXhrRequests;
        },
        apiBaseUrl: function () {
            return store.state.apiBaseUrl;
        },
        apiBaseUrlShop: function () {
            return store.state.apiBaseUrlShop;
        },
        hiveApiUrl: function () {
            return store.state.hiveApiUrl;
        },
        sendOrderUrl: function () {
            return store.state.sendOrderUrl;
        },
        baseUrl: function () {
            return store.state.baseUrl;
        },
        cart: function () {
            return store.state.cart;
        },
        cartTotalPieces: function () {
            return store.state.cartTotalPieces;
        },
        cartTotalPrice: function () {
            return store.state.cartTotalPrice;
        },
        cartUpdatedAt: function () {
            return store.state.cartUpdatedAt;
        },
        country: function () {
            return store.state.country;
        },
        currency: function () {
            return store.state.currency;
        },
        currencySign: function () {
            return store.state.currencySign;
        },
        currencyPosition: function () {
            return store.state.currencyPosition;
        },
        defaultMetaTitle: function () {
            return store.state.defaultMetaTitle;
        },
        defaultMetaDescription: function () {
            return store.state.defaultMetaDescription;
        },
        environment: function () {
            return store.state.environment;
        },
        filesBaseUrl: function () {
            return store.state.filesBaseUrl;
        },
        ipApiUrl: function () {
            return store.state.ipApiUrl;
        },
        language: function () {
            return store.state.language.toLowerCase();
        },
        metaTitleFormat: function () {
            return store.state.metaTitleFormat;
        },
        shop: function () {
            return store.state.shop;
        },
        user: function () {
            return store.state.user;
        },
        $http: () => {
            return {
                get: (link, config) => {
                    return new Promise((resolve, reject) => {
                        axios.get(link, config).then(response => {
                            resolve({...response, body: response.data});
                        }).catch(e => reject({...e, body: (e.response && e.response.data || {}), status: (e.response &&e.response.status) || 'Unknown status'}));
                    });
                },
                post: (link, data, requestConfig) => {
                    return new Promise((resolve, reject) => {
                        let config = {};

                        config['headers'] = {'Content-Type': 'multipart/form-data'};
                        if (requestConfig && requestConfig.emulateJSON) {
                            config['headers'] = {'Content-Type': 'application/json'};
                        }

                        if (requestConfig && requestConfig.responseType) {
                            config['responseType'] = requestConfig.responseType;
                        }

                        axios.post(link, data, config).then(response => {
                            resolve({...response, body: response.data});
                        }).catch(e => reject({...e, body: (e.response && e.response.data) || {}, status: (e.response && e.response.status) || 'Unknown status'}));
                    });
                },
            };
        },
        $route: () => {
            return router.currentRoute.value;
        },
        $mq: function () {
            return this.windowSize;
        },
    },

    data: () => ({
        windowSize: 'desktop',
    }),
    created: function () {
        const app = this;
        this.windowSize = window.innerWidth <= 768 ? 'mobile' : 'desktop';

        window.addEventListener('resize', function () {
            app.windowSize = window.innerWidth <= 768 ? 'mobile' : 'desktop';
        });
    },
    methods: {
        __: function (key, params) {
            var str = store.state.languageStrings[key];
            if (typeof(params) != 'undefined') {
                Object.keys(params).forEach(function (index) {
                    if (str) {
                        str = str.replace('{' + index + '}', params[index]);
                    }
                });
            }
            if (str) {
                str = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            return str;
        },
        abortAllXhrRequests: function () {
            if (!this.activeXhrRequests.length) {
                return null;
            }

            this.activeXhrRequests.forEach((request) => {
                request.abort();
            });

            store.commit('setActiveXhrRequests', []);
        },
        addXhrRequest: function (xhrRequest) {
            let xhrRequests = this.activeXhrRequests;
            xhrRequests.push(xhrRequest)
            store.commit('setActiveXhrRequests', xhrRequests);
        },
        areEqual: function(array1, array2) {
            if (array1.length !== array2.length) {
                return false;
            }

            return array1.every((element, index) => {
                return element === array2[index];
            });
        },
        areEqualObjects: function(object1, object2) {
            return (object1 && object2 && typeof object1 === 'object' && typeof object2 === 'object') ?
                (Object.keys(object1).length === Object.keys(object2).length) &&
                Object.keys(object1).reduce(function(isEqual, key) {
                    return isEqual && this.areEqualObjects(object1[key], object2[key]);
                }.bind(this), true) : (object1 === object2);
        },
        capitalizeFirstLetter: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        camelToSnakeCase: function (str) {
            return str.replace( /([A-Z])/g, "_$1").toLowerCase();
        },
        cookiesAllowAll: function () {
            this.cookiesAllowEssential();
            this.cookiesAllowAdvertising();
            this.cookiesAllowPreferences();
            this.cookiesAllowSocialMedia();
        },
        cookiesAllowEssential: function () {
            localStorage.setItem('cookies:accepted', true);
        },
        cookiesAllowAdvertising: function () {
            localStorage.setItem('cookie:advertising', true);
        },
        cookiesAllowPreferences: function () {
            localStorage.setItem('cookie:preferences', true);
        },
        cookiesAllowSocialMedia: function () {
            localStorage.setItem('cookie:social-media', true);
        },
        datetimeToDate: function (datetime) {
            if (datetime === null) {
                return null;
            }
            return datetime.slice(0, datetime.indexOf(' '));
        },
        getVideoDimensionsOf(url){
            return new Promise(resolve => {
                const video = document.createElement('video');

                video.addEventListener( "loadedmetadata", function () {
                    const height = this.videoHeight;
                    const width = this.videoWidth;

                    resolve({ height, width });
                }, false );

                video.src = url;
            });
        },
        getSavedCart: function () {
            axios.post(this.apiBaseUrlShop + 'get_user_cart', {
                token: this.user.token,
            }, {
                emulateJSON: true
            }).then(function (response) {
                let cartInfo = JSON.parse(response.data.value);

                if (cartInfo.cartUpdatedAt >= this.cartUpdatedAt) {
                    this.updateCart(cartInfo.cart, false);
                } else {
                    this.updateCart(this.cart);
                }
            }.bind(this), function() {
                this.updateCart(this.cart);
            }.bind(this));
        },
        hasInStock: function (stockId) {
            return new Promise((resolve, reject) => {
                this.$http.post(this.apiBaseUrl + 'products/stock', { ids: [stockId] }, {
                    emulateJSON: true
                })
                    .then(response => {
                        const body = response.body;

                        if (body.hasOwnProperty(stockId) && body[stockId] > 0) {
                            resolve(true);
                            return;
                        }

                        resolve(false);
                    })
                    .catch(error => reject(error));
            });

        },
        loginUser: function (user) {
            store.commit('loginUser', user);
            localStorage.setItem('user', JSON.stringify(user));

            if (!this.user) {
                router.push(this.$route.query.redirect || '/').catch(err => {
                    // Ignore the error regarding  navigating to the page they are already on.
                    if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.error(err);
                    }
                });;
                return;
            }

            this.getSavedCart();

            router.push(this.$route.query.redirect || '/').catch(err => {
                // Ignore the error regarding  navigating to the page they are already on.
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.error(err);
                }
            });;
        },
        logoutUser: function () {
            store.commit('logoutUser');
            localStorage.removeItem('user');

            this.showMyAccountModal = false

            router.push('/').catch(err => {
                // Ignore the error regarding  navigating to the page they are already on.
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.error(err);
                }
            });;
        },
        logoutUserWithoutRedirect: function () {
            store.commit('logoutUser');
            localStorage.removeItem('user');
        },
        roundNumber: function (num, scale) {
            if(!("" + num).includes("e")) {
                return +(Math.round(num + "e+" + scale)  + "e-" + scale);
            } else {
                var arr = ("" + num).split("e");
                var sig = "";
                if(+arr[1] + scale > 0) {
                    sig = "+";
                }
                return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
            }
        },
        saveCart: function () {
            if (!this.user) {
                return;
            }

            axios.post(this.apiBaseUrl + 'add_user_cart', {
                token: this.user.token,
                value: JSON.stringify({
                    cart: this.cart,
                    cartTotalPieces: this.cartTotalPieces,
                    cartTotalPrice: this.cartTotalPrice,
                    cartUpdatedAt: this.cartUpdatedAt,
                }),
            }, {
                emulateJSON: true
            });
        },
        setCountryParam: function (country) {
            store.commit('setCountry', country);
        },
        setMetaDescription: function (description) {
            document.querySelector('meta[name="description"]').setAttribute('content', description);
        },
        setMetaTitle: function (title) {
            document.title = sprintf(this.metaTitleFormat, title);
        },
        setShopParam: function (shop) {
            store.commit('setShop', shop);
        },
        setting: function (key) {
            return store.state.settings[key];
        },
        updateCart: function (products, callStoreApi) {
            callStoreApi = (typeof callStoreApi !== 'undefined') ? callStoreApi : true

            if (window.localStorage) {
                localStorage.setItem('njoy-cart', JSON.stringify(products));
            }

            store.commit('setCart', products);

            if (callStoreApi) {
                let currentDate = new Date();
                let datetime = currentDate.getFullYear() + "."
                    + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "."
                    + ('0' + currentDate.getDate()).slice(-2) + " "
                    + ('0' + currentDate.getHours()).slice(-2) + ":"
                    + ('0' + currentDate.getMinutes()).slice(-2) + ":"
                    + ('0' + currentDate.getSeconds()).slice(-2);

                store.commit('setCartUpdatedAt', datetime);
                if (window.localStorage) {
                    localStorage.setItem('njoy-cart-updated-at', datetime);
                }
            }
            this.updateCartTotal(callStoreApi);
        },
        updateCartTotal: function (callStoreApi) {
            if (!this.cart || this.cart.length === 0) {
                store.commit('setCartTotalPieces', 0);
                store.commit('setCartTotalPrice', 0);
                if (callStoreApi) {
                    this.saveCart();
                }
                return;
            }

            var cartTotalPieces = 0;
            var cartTotalPrice = 0;

            this.cart.forEach(function (product) {
                cartTotalPieces = cartTotalPieces + parseInt(product.quantity);
                cartTotalPrice = cartTotalPrice + this.roundNumber(product.price.price * product.quantity, 2);
            }.bind(this));

            store.commit('setCartTotalPieces', this.roundNumber(cartTotalPieces, 0));
            store.commit('setCartTotalPrice', this.roundNumber(cartTotalPrice, 2));

            if (callStoreApi) {
                this.saveCart();
            }
        },
        updateUser: function (user) {
            store.commit('loginUser', user);

            localStorage.setItem('user', JSON.stringify(user));
        },
        striphtml: function (value) {
            let div = document.createElement("div");
            div.innerHTML = value;
            return div.textContent || div.innerText || "";
        },
    }
}
