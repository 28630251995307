import {createStore} from "vuex";

const store = createStore({
    state: {
        activeXhrRequests: [],
        apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
        apiBaseUrlShop: import.meta.env.VITE_API_BASE_URL_SHOP,
        baseUrl: import.meta.env.VITE_BASE_URL,
        cart: [],
        cartTotalPieces: 0,
        cartTotalPrice: 0,
        cartUpdatedAt: null,
        country: 'ro',
        currency: 'RON',
        currencySign: 'Lei',
        currencyPosition: 'right',
        defaultMetaTitle: 'nJoy',
        defaultMetaDescription: 'nJoy',
        environment: import.meta.env.VITE_ENVIRONMENT,
        filesBaseUrl: import.meta.env.VITE_FILES_BASE_URL,
        hiveApiUrl: import.meta.env.VITE_HIVE_API_URL,
        ipApiUrl: import.meta.env.VITE_IP_API_URL,
        language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
        languageStrings: [],
        metaTitleFormat: '%s | nJoy',
        sendOrderUrl: import.meta.env.VITE_SEND_ORDER_URL,
        settings: [],
        shop: false,
        user: JSON.parse(localStorage.getItem('user'))
    },
    mutations: {
        loginUser: function (state, user) {
            state.user = user;
        },
        logoutUser: function (state) {
            state.user = null;
        },
        setActiveXhrRequests: function (state, xhrRequests) {
            state.activeXhrRequests = xhrRequests;
        },
        setCart: function (state, products) {
            state.cart = products;
        },
        setCartTotalPieces: function (state, total) {
            state.cartTotalPieces = total;
        },
        setCartTotalPrice: function (state, total) {
            state.cartTotalPrice = total;
        },
        setCartUpdatedAt: function (state, datetime) {
            state.cartUpdatedAt = datetime;
        },
        setCountry: function (state, country) {
            state.country = country;
        },
        setLanguage: function (state, language) {
            state.language = language;
        },
        setLanguageStrings: function (state, languageStrings) {
            state.languageStrings = languageStrings;
        },
        setSettings: function (state, settings) {
            state.settings = settings;
        },
        setShop: function (state, shop) {
            state.shop = shop;
        }
    }
});

export default store;
