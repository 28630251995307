import VueProgressBar from '@aacassandra/vue3-progressbar'
import VueScrollTo from 'vue-scrollto';
import VWow from 'v-wow';
import {createApp} from "vue";
import router from "./router.js";
import App from './App.vue';
import store from "./store.js";
import imagesLoadedDirective from "@/js/directives/imagesLoadedDirective.js";

import '../scss/app.scss';
import '../scss/iframe.scss';
import '../scss/fonts.scss';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VWow)
    .use(VueScrollTo, {
        offset: -150
    })
    .use(VueProgressBar, {
        color: '#4387cb',
        failedColor: '#ff5714',
        thickness: '5px',
        transition: {
            speed: '0.2s',
            opacity: '0.6s',
            termination: 300
        },
        autoRevert: true,
        location: 'top',
        inverse: false
    });

app.directive('images-loaded', imagesLoadedDirective);

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
    Bugsnag.start({
        apiKey: 'b06c6738d1cef0565bc7a70181e08653',
        plugins: [new BugsnagPluginVue()]
    });

    const bugsnagVue = Bugsnag.getPlugin('vue');
    app.use(bugsnagVue);
}

app.mount('#app');


// Carousel
import Siema from "siema";

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
    // Insert gtag to <head>
    let gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-1WMLLFQTBD';
    gtagScript.async = true;

    let dataLayerGTagScript = document.createElement('script');
    dataLayerGTagScript.innerHTML = "window.dataLayer = window.dataLayer || []; " +
        "function gtag(){dataLayer.push(arguments);} " +
        "gtag('js', new Date()); " +
        "gtag('config', 'G-1WMLLFQTBD', { 'send_page_view': false }); " +
        "gtag('config', 'AW-343690656', { 'send_page_view': false });";

    document.head.appendChild(gtagScript);
    document.head.appendChild(dataLayerGTagScript);
}


let navbarBurger = document.querySelector('.navbar-burger');

if (navbarBurger!= null) {
    navbarBurger.addEventListener('click', function() {
        let menu = document.querySelector('.mobile-menu');
        let header = document.querySelector('header');

        if (this.classList.contains('open')) {
            this.classList.remove('open');
            header.classList.remove('open');
        } else {
            this.classList.add('open');
            header.classList.add('open');
        }

        if (menu.classList.contains('is-open')) {
            menu.classList.remove('is-open');
            window.vueApp.unsetActiveHeaderMenuItem();
            window.vueApp.unsetActiveProductMenuItem();
        } else {
            menu.classList.add('is-open');
        }
    });
}

// mobile filter
let filterButton = document.querySelector('.main-banner__buttons__filter');

if (filterButton != null) {
    filterButton.addEventListener('click', function () {
        let filter = document.querySelector('.filter-container');

        if (filter.classList.contains('is-open')) {
            filter.classList.remove('is-open');
        } else {
            filter.classList.add('is-open');
        }
    });
}

// floating label
document.querySelectorAll('.floating-label').forEach(function (container) {
    container.querySelector('input').addEventListener('focus', function () {
        container.classList.add('is-active');
    });
    container.querySelector('input').addEventListener('blur', function () {
        if (this.value == "") {
            container.classList.remove('is-active');
        }
    });
});


document.addEventListener('touchstart', function() {}, true);

const perPage = {
    800: 2,
    1240: 3
};

Siema.prototype.navigationDots = [];

Siema.prototype.addNavigationDots = function() {
    for (let i = 0; i < this.innerElements.length / this.perPage; i++) {
        let carouselNavigationItem = document.createElement('span');

        carouselNavigationItem.addEventListener('click', function () {
            this.resetNavigationDots();
            carouselNavigationItem.classList.add('is-active');
            this.goTo(i * this.perPage, function () {
                this.updateNavigation();
            });
        }.bind(this));

        let carouselNavigation = this.selector.parentElement.querySelector('.carousel__navigation');

        if (carouselNavigation != null) {
            carouselNavigation.appendChild(carouselNavigationItem);
        }
        this.navigationDots.push(carouselNavigationItem);
    }
};

Siema.prototype.initLeftRightNavigation = function() {
    let leftNavigation = this.selector.parentElement.querySelector('.navigation-left');
    leftNavigation.addEventListener('click', function () {
        this.prev(this.perPage, function () {
            this.updateNavigation();
        }.bind(this));
        this.resetNavigationDots();
        this.updateNavigationDots();
    }.bind(this));
    if (this.config.startIndex === null || this.config.startIndex === 0) {
        leftNavigation.classList.add('is-inactive');
    }

    this.selector.parentElement.querySelector('.navigation-right').addEventListener('click', function () {
        this.next(this.perPage, function () {
            this.updateNavigation();
        }.bind(this));
        this.resetNavigationDots();
        this.updateNavigationDots();
    }.bind(this));
};

Siema.prototype.resetNavigationDots = function() {
    this.navigationDots.forEach(function (item) {
        item.classList.remove('is-active');
    });
};

Siema.prototype.updateNavigationDots = function() {
    let index = Math.ceil(this.currentSlide / this.perPage);
    this.resetNavigationDots();
    if (this.navigationDots[index]) {
        this.navigationDots[index].classList.add('is-active');
    }
};

Siema.prototype.updateNavigation = function() {
    this.selector.parentElement.querySelector('.navigation-left').classList.remove('is-inactive');
    this.selector.parentElement.querySelector('.navigation-right').classList.remove('is-inactive');

    if (Math.ceil(this.currentSlide / this.perPage) === 0) {
        this.selector.parentElement.querySelector('.navigation-left').classList.add('is-inactive')
    }

    if (Math.ceil(this.currentSlide / this.perPage) + 1 === Math.ceil(this.innerElements.length / this.perPage)) {
        this.selector.parentElement.querySelector('.navigation-right').classList.add('is-inactive')
    }
};

Siema.prototype.initThumbnailNavigation = function() {
    let carouselNavigationItems = this.selector.closest('.column').querySelectorAll('.gallery__thumbs__item');

    for (let i = 0; i < carouselNavigationItems.length; i++) {

        carouselNavigationItems[i].addEventListener('click', function () {
            carouselNavigationItems.forEach(function (item) {
                item.classList.remove('is-active');
            });

            carouselNavigationItems[i].classList.add('is-active');
            this.goTo(i * this.perPage, function () {

            });
        }.bind(this));
    }
};

Siema.prototype.updateThumbnailNavigation = function() {
    let carouselNavigationItems = this.selector.closest('.column').querySelectorAll('.gallery__thumbs__item');

    carouselNavigationItems.forEach(function (item) {
        item.classList.remove('is-active');
    });

    for (let i = 0; i < carouselNavigationItems.length; i++) {
        if (i == this.currentSlide) {
            carouselNavigationItems[i].classList.add('is-active');
        }
    }
};

if (document.getElementsByClassName("carousel").length) {
    const siema = new Siema({
        selector: '.carousel',
        perPage: perPage,
        draggable: true,
        multipleDrag: true,
        onInit: function () {
            this.addNavigationDots();
            this.initLeftRightNavigation();

            if (this.navigationDots[0]) {
                this.navigationDots[0].classList.add('is-active');
            }
        }
    });
}

if (document.getElementsByClassName("home-carousel").length) {
    const siema = new Siema({
        selector: '.home-carousel',
        perPage: 1,
        draggable: true,
        multipleDrag: true,
        onInit: function () {
            this.addNavigationDots();
            this.initLeftRightNavigation();

            if (this.navigationDots[0]) {
                this.navigationDots[0].classList.add('is-active');
            }
        }
    });
}

// Gallery
if (document.getElementsByClassName("gallery").length) {
    const gallery = new Siema({
        selector: '.gallery',
        perPage: 1,
        draggable: true,
        multipleDrag: true,
        onInit: function () {
            this.initThumbnailNavigation();
        }
    });
}

if (document.getElementsByClassName("gallery__thumbs").length) {
    const gallery = new Siema({
        selector: '.gallery__thumbs',
        perPage: {
            0: 3,
            300: 6,
            769: 4,
            900: 6,
            1240: 7
        },
        draggable: true,
        multipleDrag: true,
        onInit: function () {
            this.initLeftRightNavigation();
        }
    });
}

// Collapse
document.querySelectorAll('.collapse').forEach(function (item) {
    item.addEventListener('click', function () {
        let content = item.closest('div').querySelector('.content');

        if (content.classList.contains('is-hidden')) {
            content.classList.remove('is-hidden');
           this.innerText = '-';
        } else {
            content.classList.add('is-hidden');
            this.innerText = '+';
        }
    });
});

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

// Company key achievements
const companyKeyAchievementsImage = document.querySelector('.company__key_achievements__line img');
if (companyKeyAchievementsImage != null) {
    if (isMobileDevice()) {
        companyKeyAchievementsImage.setAttribute('src', '/assets/images/company-line-1-active-mobile.jpg');
    }
}

document.querySelectorAll('.company__key_achievements__line__element').forEach(function (item) {
    item.addEventListener('click', function () {
        let id = this.getAttribute('data-id');
        const image = this.closest('.company__key_achievements__line').querySelector('img');
        let imageUrl = '/assets/images/company-line-' + id + '-active';

        if (isMobileDevice()) {
            imageUrl = imageUrl + '-mobile.jpg';
        } else {
            imageUrl = imageUrl + '.jpg';
        }

        image.setAttribute('src', imageUrl);

        document.querySelectorAll('.company__key_achievements__list__item').forEach(function (element) {

            if (id == element.getAttribute('data-id')) {
                element.classList.add('is-active');
            } else {
                element.classList.remove('is-active');
            }
        });
    });
});


// Header search bar
const searchButtons = document.querySelectorAll('.header__user-action--search');
if (searchButtons.length) {
    searchButtons.forEach(function (searchButton) {
        searchButton.addEventListener('click', function () {
            const searchBar = document.querySelector('.search-bar');
            const headerLinks = document.querySelector('.header__links');
            if (!this.classList.contains('is-opened')) {
                searchButtons.forEach((sButton) => {
                    sButton.classList.add('is-opened');
                });
                searchBar.classList.remove('is-hidden');
                headerLinks.classList.add('is-hidden');
                searchBar.querySelector('input[name="term"]').focus();
            } else {
                searchButtons.forEach((sButton) => {
                    sButton.classList.remove('is-opened');
                });
                searchBar.classList.add('is-hidden');
                headerLinks.classList.remove('is-hidden');
            }
        });
    });
}

// Nav links
const navLinksContainer = document.querySelector('.nav-links');
if (navLinksContainer != null) {
    document.querySelectorAll('.nav-section').forEach(function (item) {
        let id = item.getAttribute('id');
        let link = document.createElement('li');
        link.setAttribute('data-id', id);
        navLinksContainer.appendChild(link);
    });

    window.addEventListener('scroll', function (event) {
        let fromTop = window.scrollY;

        navLinksContainer.querySelectorAll('li').forEach(function (link) {
            let section = document.getElementById(link.getAttribute('data-id'));

            if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
                link.classList.add('is-active');
            } else {
                link.classList.remove('is-active');
            }
        })
    })
}

// Footer collapse
document.querySelectorAll('.footer__content__mid__title').forEach(function (item) {
    item.addEventListener('click', function () {
        let container = this.closest('.footer__content__mid').querySelector('ul');

        if (container.classList.contains('is-hidden')) {
            container.classList.remove('is-hidden');
            this.querySelector('span').innerHTML = '-';
        } else {
            container.classList.add('is-hidden');
            this.querySelector('span').innerHTML = '+';
        }
    });
});

// Checkout register switcher
document.querySelectorAll('.checkout__card__modifier__radiobuttons input[type="radio"]').forEach(function (item) {
    item.addEventListener('change', function () {
        let value = this.value;

        if (value === 'register') {
            document.querySelector('.checkout__register').classList.remove('is-hidden');
            document.querySelector('.checkout__guest').classList.add('is-hidden');
        } else if (value === 'guest') {
            document.querySelector('.checkout__register').classList.add('is-hidden');
            document.querySelector('.checkout__guest').classList.remove('is-hidden');
        }
    });
});
