import mitt from 'mitt';

const emitter = mitt();

const eventBus = {
    $on: (event, handler) => {
        if (Array.isArray(event)) {
            event.forEach(item => {
                emitter.on(item, handler);
            });
            return;
        }


        emitter.on(event, handler);
    },
    $emit: (event, data) => {
        emitter.emit(event, data);
    },
    $off: (event, handler) => {
        if (Array.isArray(event)) {
            event.forEach(item => {
                emitter.off(item, handler);
            });

            return;
        }

        emitter.off(event, handler);
    },
};

export { eventBus };
